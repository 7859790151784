@import "./styles/_colors.scss";
@import "./styles/_mixins.scss";

.app-topbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .text-notification-bar {
    flex: 1;
  }

  .optionsOverlay {
    flex: 0;
  }
}

.app-content {
  padding-top: 4rem;
}

.app-content,
.optionsScreen {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex-spacer {
  flex: 1;
}

.muted {
  opacity: 0.6;
}

.text-center {
  text-align: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  // font-family: "Open Sans", sans-serif;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

button,
div {
  transition: opacity 0.5s;
}

form {
  flex-grow: 1;
}

form,
.cardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  input {
    font-size: 32px;
    margin-bottom: 0;
    border-radius: 5px !important;
  }

  button {
    text-align: center;

    &.filled {
      font-weight: bold !important;
    }
  }

  input[type="text"],
  input[type="password"],
  textarea {
    border-radius: 5px;
  }

  label {
    @include textLabel(right);

    p.muted {
      margin: 0;
    }
  }
}

button.text-link {
  display: inline-block;
  background: none !important;
  border: none;
  padding: 0 !important;
  margin-bottom: 1em !important;
  text-decoration: underline;
  cursor: pointer;
}

.show-xs {
  display: none;

  @include smallPhone() {
    display: initial;
  }
}

// Dark mode normalization
// In this case, we want to always apply them because we're trying out dark-only.
// concrete.css obeys the browser's setting, so we want to override it here
body,
a,
input,
button {
  color: #fff;
}

input {
  border: 1px solid #fff;
}

button.filled {
  background: #fff;
  color: #000;
  border-color: #fff;
}

th,
td,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  color: white;
  border-color: white;
}
