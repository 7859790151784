@import "../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.cardContents {
  .dzu-dropzone {
    border: 0;
  }

  label.dzu-inputLabel {
    @include textLabel(center);
    color: inherit;
  }

  .dzu-previewContainer {
    border: 0;
  }

  // .dzu-submitButtonContainer {
  //   display: none;
  // }
}
