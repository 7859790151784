@import "../styles/_colors.scss";
@import "../styles/_mixins.scss";

.modalOverlay {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #333;
  opacity: 0.8;

  @include darkMode {
    background-color: #222;
  }
}

.checkoutModal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .modalSpacer {
    flex: 0;
    flex-basis: 10vh;
    background-color: transparent;
  }

  .modalContent {
    z-index: 10;
    flex: 1;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    padding: 2vh 5% 30vh;
    box-shadow: 0 -4px 6px #444;
    position: relative;

    @include darkMode {
      background-color: #000;
      color: #eee;
      box-shadow: 0 -4px 6px #111;
    }

    .closeModal {
      border: 0;
      font-size: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      margin: 1rem;

      @include darkMode {
        color: #fff;
      }
    }
  }
}

.checkoutDetails {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;

  table {
    width: 90%;
    margin: 3rem auto;
    font-size: 0.9em;
    line-height: 1.4em;
  }

  td {
    border-top: 1px #666 dashed;
    border-bottom: 1px #666 dashed;
  }

  td.price {
    text-align: right;
    font-weight: bolder;
    font-family: monospace;
  }

  em {
    color: #333;
    font-size: 0.7em;
    display: block;

    @include darkMode {
      color: #ccc;
    }
  }
}

.paymentZone {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;

  #payment-form,
  div.apple-pay-wrapper {
    width: 90%;
    margin: 0 auto;
  }

  div.apple-pay-wrapper {
    margin-top: 4rem;
  }

  #payment-form {
    margin-top: 3rem;
  }

  button {
    margin-top: 1.5rem;
    border-radius: 5px;
  }

  // not used in favor of Stripe Elements but might as well keep it around
  @supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
      display: block;
      cursor: pointer;
      width: 90%;
      margin: 0 auto;
      -webkit-appearance: -apple-pay-button;
      -apple-pay-button-type: check-out;
      -apple-pay-button-style: black;

      @include darkMode {
        -apple-pay-button-style: white;
      }
    }
  }
}

.user-payment-method {
  width: 90%;
  margin: 0.5rem auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .method-info {
    flex: 2;
    font-size: 0.8em;

    .meta-line {
      span {
        text-transform: uppercase;
        font-size: 0.6em;
        font-weight: bold;
        padding: 2px 4px;
        margin-right: 4px;
        background-color: #ccc;
        border-radius: 2px;
        cursor: default;

        @include darkMode {
          background-color: #333;
        }
      }
    }
  }

  .method-button {
    flex: 1;

    button {
      font-size: 0.6em;
      padding: 0.1rem 0.2rem;
      margin: 0;
    }
  }
}

.thank-you {
  text-align: center;
  margin-top: 5rem;

  h2 {
    font-size: 2em;
    margin: 1rem;
  }

  p {
    margin: 1.5rem;
  }

  button {
    margin: 1.5rem;
    border-radius: 5px;
  }
}

p.invoice-subtext {
  width: 70%;
  margin: 2rem auto;
  font-size: 0.8em;
  line-height: 1.4em;
  color: #333;
  text-align: center;

  @include darkMode {
    color: #ccc;
  }
}
