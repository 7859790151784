@import "../styles/_colors";
@import "../styles/_mixins";

.text-notification-bar {
  p {
    @include textLabel(left);
    margin: 0;
    padding: 1.4rem;
    transition: transform 0.5s ease-in-out;
  }
}
