@mixin textLabel($align: left) {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  text-align: $align;
  margin-top: 5px;
  line-height: 1.5em;
}

@mixin darkMode() {
  // @media (prefers-color-scheme: dark) {
  @content;
  // }
}

@mixin smallPhone() {
  @media screen and (max-height: 600px) {
    @content;
  }
}

@mixin backgroundClipText() {
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}
