@import "../styles/_colors";
@import "../styles/_mixins";

.optionsScreen {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  padding: 0 15px;

  h3.score-header {
    @include textLabel(left);
    margin-top: 10rem;
  }

  table {
    margin-top: 0;
  }

  section {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .cardContainer {
    margin-bottom: 5vh;
  }

  button.smaller-button {
    width: 65%;
    margin: 2rem auto;
  }
}
