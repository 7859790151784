.inputContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  input {
    flex-shrink: 1;
  }
}

.submitContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.submitContainer,
.emailContainer {
  margin-bottom: 3vh;
}
