@import "../styles/_colors.scss";
@import "../styles/_mixins.scss";

.logo {
  font-family: "Righteous", sans-serif;
  text-align: center;
}

@keyframes bg-pan {
  from {
    background-position: top left;
  }
  to {
    background-position: bottom right;
  }
}

.logo {
  margin-bottom: 0.67rem;

  h1,
  h2 {
    text-align: center;
    background: url("./jeremy-thomas-space.jpg") repeat;
    background-size: 2503px 1708px;
    background-position: top left;
    color: transparent;
    @include backgroundClipText();

    @include darkMode() {
      // text-shadow: 2px 2px 1px $dark-mode-shadow;
      // -webkit-text-stroke-width: 1px;
      // -webkit-text-stroke-color: #fff;
      background-position: center;
    }
  }

  &.plainColor {
    h1,
    h2 {
      background: none;
      color: inherit;
    }
  }

  .logo-img {
    width: 200px;
    height: auto;
    margin: 0 auto 1rem;
  }

  &.splash-screen {
    .logo-img {
      width: 300px;
      height: auto;
      margin: 0 auto 1rem;
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 72px;
    // transition: background-position 240s;
    // animation: bg-pan 240s linear infinite;

    @include smallPhone() {
      font-size: 48px;
    }
  }

  &.withEmoji h1 {
    font-size: 60px;

    @include smallPhone() {
      font-size: 45px;
    }
  }

  &.clickable {
    h1,
    img.logo-img {
      cursor: pointer;
    }
  }

  h2 {
    margin-top: -1.5rem;
    font-size: 36px;

    @include smallPhone() {
      margin-top: -1rem;
      font-size: 28px;
    }
  }
}

.logo-small {
  text-align: left;

  .logo-img {
    width: 80px;
  }
}
