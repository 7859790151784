@import "../styles/_colors.scss";
@import "../styles/_mixins";

.modalOverlay {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #333;
  opacity: 0.8;

  // @media (prefers-color-scheme: dark) {
  background-color: #222;
  // }
}

.selectionModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .modalSpacer {
    flex: 0;
    flex-basis: 10vh;
    background-color: transparent;
  }

  .modalContent {
    z-index: 10;
    flex: 1;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    padding: 2vh 5% 0;
    box-shadow: 0 -4px 6px #444;
    position: relative;

    // @media (prefers-color-scheme: dark) {
    background-color: #000;
    color: #eee;
    box-shadow: 0 -4px 6px #111;
    // }

    .closeModal {
      border: 0;
      font-size: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      margin: 1rem;

      // @media (prefers-color-scheme: dark) {
      color: #fff;
      // }
    }
  }
}
