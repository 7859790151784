@import "../styles/_colors.scss";
@import "../styles/_mixins";

.guesserInterface {
  flex: 1;
  display: flex;
  flex-direction: column;

  .bigPrompt {
    margin-top: 0;
  }

  h3 {
    @include textLabel(left);
  }

  blockquote {
    margin-top: 0;
  }

  .guessInput {
    flex: 2;

    button {
      display: inline-block;
    }
  }
}

.guessSubmit {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;

  button {
    width: 60%;
  }
}
