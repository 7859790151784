@import "../styles/_colors.scss";
@import "../styles/_mixins.scss";

.optionsOverlay {
  text-align: right;

  button {
    @include textLabel(center);
    border: none;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 6px;
  }
}
