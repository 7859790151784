@import "../styles/_colors.scss";
@import "../styles/_mixins.scss";

.responseViewer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-x: hidden;

  blockquote {
    flex: 0;
    margin-bottom: 0;
  }

  .currentAnswer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cardContainer {
      position: relative;
      margin-bottom: 0;
    }

    .cardSpacer {
      visibility: hidden;
    }

    h2 {
      flex: 0;
      margin-top: -25px;
    }
  }

  .answerText {
    margin-bottom: 0;
  }

  .responseSubtitle {
    @include textLabel(right);
  }

  .responsePager {
    flex: 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
}
