@import "../styles/_mixins";

.atv-status {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
}

.atv-connected {
  color: #3c763d;
}

.atv-disconnected {
  color: #a94442;
}

.lobby {
  margin-top: 4rem;

  .cardContainer {
    margin-bottom: 5vh;

    > h2 {
      margin-bottom: 0;
    }

    ul {
      margin: 0;
    }
  }
}
