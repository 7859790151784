@import "../styles/_mixins.scss";
@import "../styles/_colors.scss";

.currentPin {
  text-align: center;

  h2 {
    margin: 0;
    font-size: 6rem;
  }

  p {
    margin: 0;
    @include textLabel(center);
  }
}
