@import "../styles/_colors";
@import "../styles/_mixins";

.point-totals {
  margin: 0;

  th {
    @include textLabel(left);

    &.data-numeric {
      text-align: right;
    }
  }

  td.data-numeric {
    text-align: right;
    font-family: monospace;
    font-size: 16px;
  }

  tr:last-child td {
    border-bottom: 0;
  }
}
