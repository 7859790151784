@import "../styles/_mixins.scss";

.answerForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > h2 {
    margin: 0;
  }
}

.composeContainer {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  div.flex-spacer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  button {
    width: initial;
  }

  label {
    @include textLabel(center);
    padding: 10px 0;
  }
}

.submitContainer {
  flex: 0;
  text-align: center;
}
